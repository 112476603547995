/**
 * Created by vaibhav on 2/4/18
 */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import Link from "gatsby-link"
import IRPageWrapper from "../../layouts/IRPageWrapper"
import IRNav from "../../components/IRNav"
import IRBG from "../../components/IRBG"

class PolicyPage extends Component {
  render() {
    return (
      <IRPageWrapper>
        <IRBG />
        <Helmet>
          <title> Investory Relations Privacy Policy - E.A. Technique</title>
        </Helmet>
        <section className="hero is-small">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <div className="section">
                    <h1 className="title">Investor Relations Privacy Policy</h1>
                    <IRNav />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="column is-8 is-offset-2" id="port-section">
            This Investor Relations Website ("IR Website") is designed and
            maintained by Insage (MSC) Sdn Bhd (hereinafter referred to as
            "Insage", "we", "our" or "us") and any pages thereof including but
            not limited to the information, text, images, links, sound, graphics
            and video sequences displayed therein are referred to as the
            "Materials".
            <br />
            <br />
            We have created this Privacy Policy to set out our practices
            pertaining to the collection, store, use and process of your
            personal information we collect from you within the IR Website.
            <br />
            <br />
            This Privacy Policy applies when you use this IR Website as a casual
            browser. When you provide personal information to us through the IR
            Website, the data controller is Insage (MSC) Sdn Bhd.
            <br />
            <br />
            From time to time, please review any changes that may have been made
            on the IR Website and we serve the right to change this Privacy
            Policy at our sole discretion from time to time.
            <br />
            <br />
            <b>Collection of Personal Information</b>
            <br />
            Personal information on this IR Website is collected by Insage on
            its own behalf and on behalf of the Company. We operate this IR
            Website on behalf of the Company.
            <br />
            <br />
            Generally you can visit the IR Website as a casual browser without
            providing personal information.
            <br />
            <br />
            We collect your personal information when you subscribe to the News
            Alert through the IR Website. We store and process your personal
            information which include your name and email address to use the
            News Alert service. Once the subscription is successful, you will
            receive the Company's latest news and announcements via email.
            <br />
            <br />
            In addition, we will collect and use your personal information at
            any times and from time to time (including but not limited to) only
            where we have legitimate business reasons and are legally entitled
            to do so, or when it is required to verify your personal information
            by us, the Company or our service providers for the following
            situations:
            <br />
            <br />
            <ul>
              <li>1. when you request for services or technical support;</li>
              <li>
                2. when you report a problem or make any inquiries or
                suggestions in respect of the IR Website;
              </li>
              <li>
                3. when you contact Insage, we may keep a record of that
                correspondence.
              </li>
            </ul>
            <br />
            <br />
            When you browse the IR Website, we may automatically receive
            information about your interaction with the IR Website which will be
            stored in our server log files. This information includes your
            internet protocol ("IP") address, browser type, operating system,
            date and time of your visit, cookie information, the extent of
            repeat usage, usage by registered and unregistered users, pages you
            have seen in each service requested, users progress, number of
            entries, submissions and status in services, traffic patterns, areas
            of the IR Website you have visited and your visiting patterns in the
            aggregate.
            <b>
              <br />
              <br />
              Use of Information
              <br />
            </b>
            In general, Insage does not sell your personal information to
            anyone. The information which we collect from you will be used in
            the ordinary course of business including but not limited to the
            provision of specific services to you as requested, to prevent any
            fraud or illegal activities, and to prepare statistics concerning
            the visitors' use of our services and the IR Website.
            <br />
            <br />
            Save as provided in this Privacy Policy, we will notify you at the
            time of data collection or transfer if your data will be shared with
            a third party and you will always have the option of not permitting
            the transfer.
            <br />
            <br />
            We may use your email address to contact you regarding
            administrative notices, reminders, new product offerings, legal
            processes, and communications relevant to your use of the IR
            Website. Information that we maintain about you and information from
            your current and past activities on the IR Website will be used in
            the event of troubleshooting or disputes.
            <br />
            <br />
            <b>Sharing of Information</b>
            <br />
            Insage will not share your personal information with any third party
            unless:
            <ul>
              <li>1. your consent is obtained</li>
              <li>
                2. a service provided on the IR Website requires interaction
                with a third party or is provided by a third party
              </li>
              <li>3. pursuant to legal action or law enforcement</li>
              <li>
                4. the IR Website is purchased by a third party which entitled
                the third party to use the data in the same manner as set forth
                in this Privacy Policy;
              </li>
              <li>
                5. under special circumstances such as when we believe in good
                faith that the law requires it or under the circumstances
                described below.
              </li>
            </ul>
            <br />
            Some of the user data in the aggregate will be used to compile
            statistics that shows, by for example, the numbers and frequency of
            the users using the IR Website or viewing individual contents. These
            aggregated statistics do not contain any personally identifying
            information about you. Insage may disclose such aggregated
            statistics in order to describe our services to current and
            prospective users, clients, business partners, advertisers and other
            third parties, and/or for other lawful purposes.
            <br />
            <br />
            In the event where Insage have reason believe that you are violating
            or may be in breach of the Terms of Use or any written law, we may
            disclose the information we have obtained through the IR Website
            about you to third parties to the extent that it is reasonably
            necessary to prevent, remedy or take action in respect of such
            conduct.
            <br />
            <br />
            Insage may disclose or access your user account information when we
            believe in good faith that we are obliged to comply with any legal
            obligations, or we are permitted by law to do so or otherwise to
            protect ourselves or the Company.
            <br />
            <br />
            <b>Data Security</b>
            <br />
            <br />
            Insage will use its commercially reasonable effort to safeguard your
            personal information we collect from you online through employing
            appropriate physical and technological security measure. When we
            outsource any processes, we will also ensure that our service
            provider has applied appropriate security measure and that your
            personal information is treated securely.
            <br />
            <br />
            When you give your personal information, that information may be
            sent electronically to servers within the territorial of Malaysia
            irrespective of where you originally entered the information.
            <br />
            <br />
            Insage uses industry standard Secure Socket Layer (SSL) protocol and
            encryption technology to protect data in transmit. When you provide
            your personal information online through the IR Website, such
            information will be encrypted into codes rendering it unreadable to
            anyone before being securely transmitted over the Internet.
            <br />
            <br />
            Unfortunately, no data transmission over the Internet can be
            guaranteed to be 100% secure. Despite our efforts to protect your
            personal information, Insage do not warrant the security of any
            information you transmit to us, or from our online services.
            Accordingly, all such transmission of information is carried out at
            your own risk. Once we receive your transmission, we will make our
            commercially reasonable effort to ensure its security in our system.
            <br />
            <br />
            <b>Cookies</b>
            <br />
            <br />
            In common with many other website operators, Insage may use cookies
            to validate, store and sometimes track information about your use of
            the IR Website. Cookies are small text file that are sent to your
            browser from a web server and stored on your computer by acting as a
            sort of "bookmark" within the website. It enables system to
            recognize a user preference and respond appropriately, and cannot be
            executed as code or deliver viruses. No personal information is
            stored within the cookies.
            <br />
            <br />
            <br />
            We use cookies for the following reasons:
            <ul style={{ listStyle: `disc` }}>
              <li>
                It enables the system to deliver a better user preference and
                personalised content that a subscriber will see on certain parts
                of the IR Website; and
              </li>
              <li>
                It enables the system to monitor the IR Website performance and
                to identify any areas of the IR Website that may need
                maintenance.
              </li>
            </ul>
            <br />
            <br />
            Most browsers automatically accept cookies. You can modify your
            browser to prevent cookies being stored but to do so you may not be
            able to use certain parts of the IR Website.
            <br />
            <br />
            <b>Your right to delete your personally identifiable information</b>
            You may unregister from the News Alert service from the IR Website.
            When you unregister from the News Alert service, you will not
            receive the Company's latest news and announcements via email and
            your personal information will remain within our archive records in
            order to comply with legal obligations or to resolve disputes.
            <br />
            <br />
            <b>Consent</b>
            <br />
            <br />
            By subscribing the Company's News Alert with personal information or
            by the continued use of this service and IR Website, you are
            consenting to our collection, store, use and process of your
            personal data as described in this Privacy Policy.
            <b>Contact Us</b>
            By Email: info@insage.com.myBy Telephone: 03-7735 2238By Mail:
            Insage (MSC) Sdn Bhd (734861-D)Level 31, MYEG Tower, Empire CityNo.
            8, Jalan Damansara, PJU 847820 Petaling JayaSelangor Darul Ehsan,
            Malaysia.
            <table id="customers">
              <tr>
                <td>By Email:</td>
                <td>info@insage.com.my</td>
              </tr>
              <tr>
                <td>By Telephone</td>
                <td>03-7735 2238</td>
              </tr>
              <tr>
                <td>By Mail</td>
                <td>
                  Insage (MSC) Sdn Bhd (734861-D) Level 31, MYEG Tower, Empire
                  City No. 8, Jalan Damansara, PJU 8 47820 Petaling Jaya
                  Selangor Darul Ehsan, Malaysia.
                </td>
              </tr>
            </table>
          </div>
        </section>
      </IRPageWrapper>
    )
  }
}

PolicyPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default PolicyPage
